<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col>
        <v-sheet class="pa-8 mb-8">
          <div class="text-center">
            <h1 class="mb-5">{{ $t("offerings-lecture-left") }}</h1>
          </div>
          <div class="text-center mb-5">
            <p
              v-for="paragraph in $t('offerings-lecture-text-left')"
              :key="paragraph"
            >
              {{ paragraph }}
            </p>
          </div>
          <v-row>
            <v-col
              v-for="(button, index) in this.buttonsLeft"
              :key="index"
              align="center"
            >
              <v-btn color="accent" :to="button.url">
                <v-icon class="mr-1">{{ button.icon }}</v-icon>
                {{ $t(button.slug) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-8 mb-8">
          <div class="text-center">
            <h1 class="mb-5">{{ $t("offerings-lecture-right") }}</h1>
          </div>
          <div class="text-center mb-5">
            <p
              v-for="paragraph in $t('offerings-lecture-text-right')"
              :key="paragraph"
            >
              {{ paragraph }}
            </p>
          </div>
          <v-row>
            <v-col
              v-for="(button, index) in this.buttonsRight"
              :key="index"
              align="center"
            >
              <v-btn color="accent" :to="button.url">
                <v-icon class="mr-1">{{ button.icon }}</v-icon>
                {{ $t(button.slug) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "lecture",
  components: {
    Breadcrumbs
  },
  data: () => ({
    buttonsLeft: [
      {
        slug: "contact",
        icon: "mdi-email-edit-outline",
        url: "/contact"
      }
    ],
    buttonsRight: [
      {
        slug: "calendar",
        icon: "mdi-calendar-multiple",
        url: "/calendar"
      },
      {
        slug: "membership",
        icon: "mdi-account-multiple-plus-outline",
        url: "/association/membership"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("offerings"),
          disabled: true
        },
        {
          text: this.$t("offerings-lecture"),
          disabled: true
        }
      ];
    }
  }
};
</script>
